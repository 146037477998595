import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './ForgotPassword.css'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://kborder.onrender.com/api/admin/forgot-password",
        {
          email,
        }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/"); // Redirect after 3 seconds
      }, 3000);
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div className="forgot-password-wrapper">
      <div className="forgot-password-form-container">
        <h2 className="forgot-password-title">Forgot Your Password?</h2>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <input
            type="email"
            className="forgot-password-input"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="forgot-password-button">
            Send Reset Link
          </button>
        </form>

        {message && <p className="forgot-password-success">{message}</p>}
        {error && <p className="forgot-password-error">{error}</p>}

        <p className="forgot-password-back-to-login">
          <a href="/">Back to Login</a>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
