import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SetWebsiteLinkForSearch.css";

function SetWebsiteLinkForSearch() {
  const [websiteName, setWebsiteName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [websiteImage, setWebsiteImage] = useState(null);
  const [websiteData, setWebsiteData] = useState([]);
  const [imageToUpdate, setImageToUpdate] = useState(null);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleImageChange = (e) => {
    setWebsiteImage(e.target.files[0]);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("name", websiteName);
    formData.append("link", websiteLink);
    if (websiteImage) {
      formData.append("image", websiteImage);
    }

    try {
      await axios.post("https://kborder.onrender.com/api/save-website-data", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Website saved successfully!");
      setWebsiteName("");
      setWebsiteLink("");
      setWebsiteImage(null);
      fetchWebsiteData(); // Refresh data
    } catch (error) {
      setError("Failed to save the website. Please try again.");
      console.error("Error saving website:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWebsiteData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://kborder.onrender.com/api/get-website-data"
      );
      console.log(response.data);
      setWebsiteData(response.data);
    } catch (error) {
      setError("Error fetching website data.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await axios.delete(`https://kborder.onrender.com/api/delete-website-data/${id}`);
      alert("Website deleted successfully!");
      fetchWebsiteData();
    } catch (error) {
      setError("Failed to delete the website.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpdate = async (id) => {
    if (!imageToUpdate) {
      alert("Please select an image to update.");
      return;
    }

    const formData = new FormData();
    formData.append("image", imageToUpdate);

    try {
      await axios.put(
        `https://kborder.onrender.com/api/update-website-data/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert("Image updated successfully!");
      setImageToUpdate(null);
      fetchWebsiteData();
    } catch (error) {
      setError("Failed to update the image.");
      console.error(error);
    }
  };

  const handleSelectWebsiteForImageUpdate = (id) => {
    setSelectedWebsiteId(id);
  };

  useEffect(() => {
    fetchWebsiteData();
  }, []);

  return (
    <div className="website-settings-container">
      {/* Header */}
      <h1 className="settings-header">Manage Website Links and Images</h1>
      {error && <p className="error-message">{error}</p>}

      {/* Form Section */}
      <div className="form-section">
        <form className="website-form" onSubmit={handleSave}>
          <div className="form-group">
            <label className="form-label">Website Name:</label>
            <input
              type="text"
              className="form-input"
              value={websiteName}
              onChange={(e) => setWebsiteName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label">Website Link:</label>
            <input
              type="url"
              className="form-input"
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label">Upload Image:</label>
            <input
              type="file"
              className="form-file-input"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <button
            // type="submit"
            className="form-submit-buttons"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Website"}
          </button>
        </form>
      </div>

      {/* Website Data Section */}
      <div className="data-section">
        <h2 className="data-header">Website Data</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : websiteData.length > 0 ? (
          <table className="data-table">
            <thead>
              <tr className="table-header-row">
                <th className="table-header-cell">Website Name</th>
                <th className="table-header-cell">Website Link</th>
                <th className="table-header-cell">Image</th>
                <th className="table-header-cell">Actions</th>
              </tr>
            </thead>
            <tbody>
              {websiteData.map((website, index) => (
                <tr className="table-row" key={index}>
                  <td className="table-cell">{website.name}</td>
                  <td className="table-cell">
                    <a
                      href={website.link}
                      className="website-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website.link}
                    </a>
                  </td>
                  <td className="table-cell">
                    {website.image && (
                      <img
                        className="website-image"
                        src={`https://kborder.onrender.com/uploads/${website.image}`}
                        alt={website.name}
                      />
                    )}
                  </td>
                  <td className="table-cell actions">
                    <button
                      className="delete-image"
                      onClick={() => handleDelete(website._id)}
                    >
                      Delete
                    </button>
                    <button
                      className="update-image"
                      onClick={() =>
                        handleSelectWebsiteForImageUpdate(website._id)
                      }
                    >
                      Update Image
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No website data found.</p>
        )}
      </div>

      {/* Image Update Modal */}
      {selectedWebsiteId && (
        <div
          className="modal-overlay"
          onClick={() => setSelectedWebsiteId(null)}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing on modal content click
          >
            <h3 className="modal-header">Update Website Image</h3>
            <input
              type="file"
              className="update-image-input"
              accept="image/*"
              onChange={(e) => setImageToUpdate(e.target.files[0])}
            />
            <button
              className="update-image-submit-button"
              onClick={() => handleImageUpdate(selectedWebsiteId)}
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Image"}
            </button>
            <button
              className="modal-close-button"
              onClick={() => setSelectedWebsiteId(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetWebsiteLinkForSearch;
