import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SuperAdmin.css";

const SuperAdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem("authToken");
      const userRole = localStorage.getItem("userRole");

      if (userRole !== "super_admin") {
        setError("You do not have the necessary permissions.");
        navigate("/");
        return;
      }

      const response = await axios.get(
        "https://kborder.onrender.com/api/admin/approve-or-deny",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setUsers(response.data.users);
    } catch (error) {
      setError("Failed to load users.");
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const handleAction = async (userId, action) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!["approve", "deny"].includes(action)) {
        setError("Invalid action.");
        return;
      }

      const response = await axios.post(
        "https://kborder.onrender.com/api/admin/approve-or-deny",
        { userId, action },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update users state immediately after the action
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId
            ? { ...user, access: action === "approve" ? "approved" : "denied" }
            : user
        )
      );

      alert(`${action === "approve" ? "Approved" : "Denied"} successfully!`);
      alert(response.data.message);
    } catch (error) {
      console.error(`Error performing ${action}:`, error);
      setError(`Failed to ${action} the user. Please try again.`);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="admin-panel-container">
      <h1 className="admin-panel-heading">Super Admin Panel</h1>
      {users.length === 0 ? (
        <p className="no-requests-text">No user requests available.</p>
      ) : (
        <div className="table-wrapper">
          <table className="admin-table">
            <thead className="table-header">
              <tr>
                <th>Sr. No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id} className="admin-row">
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.employeeId}</td>
                  <td className="action-buttons">
                    <button
                      className={`approve-button ${
                        user.access === "approved" ? "disabled" : ""
                      }`}
                      onClick={() =>
                        user.access === "pending" || user.access === "denied"
                          ? handleAction(user._id, "approve")
                          : null
                      }
                      disabled={user.access === "approved"}
                    >
                      {user.access === "approved" ? "Approved" : "Approve"}
                    </button>
                    <button
                      className={`deny-button ${
                        user.access === "pending" ? "disabled" : ""
                      }`}
                      onClick={() =>
                        user.access === "pending" || user.access === "approved"
                          ? handleAction(user._id, "deny")
                          : null
                      }
                      disabled={user.access === "pending"}
                    >
                      {user.access === "denied" ? "Denied" : "Deny"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SuperAdminPanel;
