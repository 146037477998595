import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("black");
  const navigate = useNavigate(); // Use navigate for redirection

  // Function to clear error message when the user starts typing
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setMessage(""); // Clear error message when user modifies input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = { email, employeeId, password };

    try {
      // Make the POST request to the backend with login data
      const response = await axios.post(
        "https://kborder.onrender.com/api/admin/login", // Replace with your backend URL
        loginData
      );

      console.log("Login Date :", loginData);

      // If login is successful
      if (response.status === 200) {
        const { token, message, role } = response.data; // Extract token, message, and role from response

        // Save the token and role in localStorage
        localStorage.setItem("authToken", token);
        localStorage.setItem("userRole", role);

        // Set success message and color
        setMessage(message);
        setMessageColor("green");

        // Redirect based on role
        setTimeout(() => {
          if (role === "super_admin") {
            navigate("admin/"); // Replace with the super admin dashboard route
          } else {
            navigate("admin/"); // Replace with the admin dashboard route
          }
        }, 1000);
      }
    } catch (error) {
      // Handle error and set error message
      const errorMessage = error.response
        ? error.response.data.message
        : "Login failed";

      // Check for specific messages like access denied
      if (errorMessage.includes("Access denied")) {
        setMessage("Your access has been denied by the super admin.");
      } else if (errorMessage.includes("Access pending")) {
        setMessage("Your access is pending approval. Please wait.");
      } else {
        setMessage(errorMessage);
      }

      setMessageColor("red");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Admin Login</h2>
        {message && (
          <p
            className={`message ${
              messageColor === "green" ? "message-success" : "message-error"
            }`}
          >
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-input-login"
              value={email}
              onChange={handleInputChange(setEmail)} // Using handleInputChange for email
              required
            />
          </div>
          <div className="form-group">
            <label>Employee ID</label>
            <input
              type="text"
              className="form-input-login"
              value={employeeId}
              onChange={handleInputChange(setEmployeeId)} // Using handleInputChange for employeeId
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-input-login"
              value={password}
              onChange={handleInputChange(setPassword)} // Using handleInputChange for password
              required
            />
          </div>
          <p>
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        <div className="register-link">
          <p>
            I don't have an account?{" "}
            <a href="/admin/register" className="register-page-link">
              Register here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
