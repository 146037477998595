import React, { useState, useEffect } from "react";
import axios from "axios";
import './AdminLogoChnage.css'

const AdminLogoChange = () => {
  const [logo, setLogo] = useState(null); // State for holding the selected logo file
  const [loading, setLoading] = useState(false); // Loading state for the upload
  const [message, setMessage] = useState(""); // Success or error message
  const [currentLogo, setCurrentLogo] = useState(""); // To store and display the current logo

  const API_URL = "https://kborder.onrender.com"; // Backend URL

  // Fetch current logo on component mount
  useEffect(() => {
    const fetchCurrentLogo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/current-logo`);
        console.log("Response from backend:", response.data); // Log the full response

        if (response.data.success && response.data.logo) {
          // Ensure the logo URL is a complete URL
          setCurrentLogo(`${API_URL}${response.data.logo}`);
          console.log("Full logo URL:", `${API_URL}${response.data.logo}`); // Log the full URL
        }
      } catch (error) {
        console.error("Error fetching current logo:", error);
      }
    };

    fetchCurrentLogo();
  }, []);

  const handleLogoUpload = async (event) => {
    event.preventDefault();

    if (!logo) {
      setMessage("Please select a logo file first.");
      return;
    }

    const formData = new FormData();
    formData.append("logo", logo);

    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/upload-logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        setMessage("Logo uploaded successfully!");
        // Update the displayed logo with the new logo
        setCurrentLogo(`${API_URL}/uploads/${response.data.logo}`);
      } else {
        setMessage("Failed to upload logo.");
      }
    } catch (error) {
      setMessage("An error occurred while uploading the logo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Admin - Change Logo</h2>

      {/* Display current logo */}
      <div>
        <h3>Current Logo:</h3>
        <img
          src={currentLogo || "/default-logo.png"} // Fallback if logo is not set
          alt="Current Logo"
          style={{ width: "200px", height: "auto" }}
        />
      </div>

      <form onSubmit={handleLogoUpload}>
        <div>
          <label htmlFor="logoUpload">Choose a new logo:</label>
          <input
            type="file"
            accept="image/*"
            id="logoUpload"
            onChange={(e) => setLogo(e.target.files[0])}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Upload Logo"}
        </button>
      </form>

      {message && (
        <p className={message.includes("error") ? "error" : ""}>{message}</p>
      )}
    </div>
  );
};

export default AdminLogoChange;
