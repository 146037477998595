import { Route, Routes } from "react-router-dom";
import Register from "./Registration"; // Default import
import Login from "./Login"; // Default import or named import depending on your export
import Admin from "./Admin"; // Default import
import PrivateRoute from "./PrivateRoute"; // Default import (make sure this is correct)
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Register Route */}
        <Route path="/admin/register" element={<Register />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        {/* Login Route */}
        <Route path="/" element={<Login />} />


        {/* Protected Admin Route */}
        <Route
          path="/admin/*"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />

        {/* <Route path="/" element={<Admin />} /> */}
      </Routes>
    </div>
  );
}

export default App;
