import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PriceDetailsPage.css";

function PricingDetailPage() {
  const [domainDetails, setDomainDetails] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track expanded list index

  useEffect(() => {
    const fetchDomainDetails = async () => {
      try {
        const response = await axios.get(
          "https://kborder.onrender.com/api/pricing/get-pricing-domain-details"
        );
        setDomainDetails(response.data); // Save the fetched data in state
        console.log(response.data); // Optional: Check the data in the console
      } catch (error) {
        console.error("Error fetching domain details:", error);
      }
    };

    fetchDomainDetails();
  }, []);

  const toggleDetail = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle expand/collapse
  };

  // Function to handle the deletion of a domain entry
  const handleDelete = async (id) => {
    try {
      // Send DELETE request to the backend to delete the domain entry
      await axios.delete(
        `https://kborder.onrender.com/api/pricing/delete-pricing-domain/${id}`
      );

      // Remove the deleted domain from the state to update the UI
      setDomainDetails(domainDetails.filter((item) => item._id !== id));
      alert("Domain deleted successfully!"); // Show success message
    } catch (error) {
      console.error("Error deleting domain details:", error);
    }
  };

  return (
    <div>
      <h1>Pricing Domain Details</h1>
      {domainDetails.length > 0 ? (
        <ul>
          {domainDetails.map((item, index) => (
            <li
              key={item._id}
              className={`pricing-card ${
                expandedIndex === index ? "expanded" : ""
              }`}
            >
              <div className="button-container">
                <button
                  className="toggle-button"
                  onClick={() => toggleDetail(index)}
                >
                  {item.fullName}
                  <span className="arrow">
                    {expandedIndex === index ? "▲" : "▼"}
                  </span>
                </button>

                {/* Delete Button */}
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item._id)}
                >
                  Delete
                </button>
              </div>

              {expandedIndex === index && (
                <div className="pricing-details">
                  <p>
                    <strong>Full Name:</strong> {item.fullName}
                  </p>
                  <p>
                    <strong>Email:</strong> {item.email}
                  </p>
                  <p>
                    <strong>Business Name:</strong> {item.businessName}
                  </p>
                  <p>
                    <strong>Domain Name:</strong> {item.domainName}
                  </p>
                  <p>
                    <strong>Amount Paid:</strong> ₹ {item.amount}
                  </p>
                  {/* Add more fields as necessary */}
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No domain details available.</p>
      )}
    </div>
  );
}

export default PricingDetailPage;
