import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PostAllImages.css";

function PostAllImages() {
  const [images, setImages] = useState([]);
  const [imageTitle, setImageTitle] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [category, setCategory] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchImages = async (filterCategory = "") => {
    setLoading(true);
    try {
      const response = await axios.get("https://kborder.onrender.com/api/get-images");
      const allImages = response.data.data;
      setImages(allImages);

      // Filter images based on provided category, if any
      const filtered = filterCategory
        ? allImages.filter((img) => img.category === filterCategory)
        : allImages;
      setFilteredImages(filtered);
    } catch (error) {
      console.error("Error fetching images:", error);
      setMessage({ type: "error", text: "Failed to load images" });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("title", imageTitle);
    formData.append("category", category);

    setLoading(true);
    try {
      await axios.post("https://kborder.onrender.com/api/images-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage({ type: "success", text: "Image uploaded successfully!" });
      setImageTitle("");
      setSelectedImage(null);

      // After upload, fetch images and set filter to uploaded category
      fetchImages(category);
    } catch (error) {
      console.error("Error uploading image:", error);
      setMessage({ type: "error", text: "Failed to upload image" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    if (selectedCategory === "") {
      setFilteredImages(images);
    } else {
      const imagesByCategory = images.filter(
        (image) => image.category === selectedCategory
      );
      setFilteredImages(imagesByCategory);
    }
  };

  return (
    <div className="postimages">
      <h2>Upload Images</h2>
      <form className="Image-Form" onSubmit={handleImageUpload}>
        <input
          type="text"
          placeholder="Image Title"
          value={imageTitle}
          onChange={(e) => setImageTitle(e.target.value)}
          required
        />
        <input
          type="file"
          accept="image/*,image/gif"
          onChange={(e) => setSelectedImage(e.target.files[0])}
          required
        />
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <option value="">Select Category</option>
          <option value="home">Home</option>
          <option value="about-us">About Us</option>
          <option value="unique-values">Unique Values</option>
          <option value="quote">Quote</option>
          <option value="pricing">Pricing</option>
        </select>
        <div className="button">
          <button type="submit">Upload</button>
        </div>
      </form>

      {message && (
        <div
          className={`message ${
            message.type === "error" ? "error" : "success"
          }`}
        >
          {message.text}
        </div>
      )}

      {loading && <div className="loading">Loading...</div>}

      <h2>Uploaded Images</h2>
      <select value={category} onChange={handleCategoryChange}>
        <option value="">All Categories</option>
        <option value="home">Home</option>
        <option value="about-us">About Us</option>
        <option value="unique-values">Unique Values</option>
        <option value="quote">Quote</option>
        <option value="pricing">Pricing</option>
      </select>

      <div className="image-grid">
        {filteredImages.map((image) => (
          <div key={image._id}>
            <h4>
              {image.title} - {image.category}
            </h4>
            <img
              src={`https://kborder.onrender.com/${image.imageUrl}`}
              alt={image.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PostAllImages;
