import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("authToken"); // Ensure consistent token name

  // If no token found, redirect to login page
  if (!token) {
    return <Navigate to="/" />; // Redirect to the login page if no token
  }

  return children; // If token exists, render the protected component
};

export default PrivateRoute;
