import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import './ResetPassword.css'

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally: Validate token when component loads
    if (!token) {
      setError("Invalid or expired token.");
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(
        `https://kborder.onrender.com/api/admin/reset-password/${token}`,
        {
          password,
        }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/"); // Redirect after 3 seconds
      }, 3000);
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div className="reset-password-wrapper">
      <div className="reset-password-form-container">
        <h2 className="reset-password-title">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="reset-password-form">
          <input
            type="password"
            className="reset-password-input"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            className="reset-password-input"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit" className="reset-password-button">
            Reset Password
          </button>
        </form>

        {message && <p className="reset-password-success">{message}</p>}
        {error && <p className="reset-password-error">{error}</p>}

        <p className="reset-password-back-to-login">
          <a href="/login">Back to Login</a>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
