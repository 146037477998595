import { Link, Route, Routes, useNavigate } from "react-router-dom";
import GetQuote from "./GetQuote";
import PricingDetailPage from "./PricingDetailPage";
import PostAllImages from "./PostAllImages";
import SetWebsiteLinkForSearch from "./SetWebsiteLinkForSearch";
import AdminLogoChange from "./AdminLogoChange ";
import VideoCompo from "./VideoCompo";
import SuperAdminPanel from "./SuperAdmin"; // Add this if you have a dedicated Super Admin component
import { BsChatLeftQuoteFill } from "react-icons/bs";
import { GiPriceTag } from "react-icons/gi";
import { FaImages } from "react-icons/fa";
import { IoStorefront } from "react-icons/io5";
import { IoLogoBuffer } from "react-icons/io";
import { IoIosLogOut } from "react-icons/io";
import "./Admin.css";
import { useState, useEffect } from "react";

function Admin() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null); // Initialize state to null to handle loading

  // Handle Logout
  const handleLogout = () => {
    localStorage.removeItem("authtoken"); // Removing the token
    localStorage.removeItem("userRole"); // Removing the userRole
    navigate("/"); // Redirect to login
  };

  // Fetch User Role
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const role = localStorage.getItem("userRole");

    // If no token or userRole, redirect to login
    if (!token || !role) {
      handleLogout();
      return;
    }

    setUserRole(role); // Set user role from localStorage directly
  }, [navigate]);

  // If role is still being fetched, show loading
  if (userRole === null) return <div>Loading...</div>;

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          {/* Sidebar */}
          <div className="col-md-3 leftpanel">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to="/admin/get-quote" className="list-group-link">
                  <BsChatLeftQuoteFill className="icon" />
                  <span className="link-text">
                    Get Request <br />
                    Quote Details
                  </span>
                </Link>
              </li>
              <li className="list-group-item">
                <Link
                  to="/admin/get-pricing-detail"
                  className="list-group-link"
                >
                  <GiPriceTag className="icon" />
                  <span className="link-text">Get Pricing Details</span>
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/admin/post-all-images" className="list-group-link">
                  <FaImages className="icon" />
                  <span className="link-text">Set All Images</span>
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/admin/set-web-link" className="list-group-link">
                  <IoStorefront className="icon" />
                  <span className="link-text">Set All Store Links</span>
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/admin/change-logo" className="list-group-link">
                  <IoLogoBuffer className="icon" />
                  <span className="link-text">Change Logo</span>
                </Link>
              </li>
              {/* Super Admin Tab - Conditionally render */}
              {userRole === "super_admin" && (
                <li className="list-group-item">
                  <Link to="/admin/super-admin" className="list-group-link">
                    <IoLogoBuffer className="icon" />
                    <span className="link-text">Super Admin Panel</span>
                  </Link>
                </li>
              )}
            </ul>
            <button
              onClick={handleLogout} // Use handleLogout here
              className="btn btn-danger logout-btn"
            >
              <IoIosLogOut className="icon" />
              Logout
            </button>
          </div>

          {/* Main Content Area */}
          <div className="col-9 full-height">
            <Routes>
              <Route path="/" element={<VideoCompo />} />
              <Route path="/get-quote" element={<GetQuote />} />
              <Route
                path="/get-pricing-detail"
                element={<PricingDetailPage />}
              />
              <Route path="/post-all-images" element={<PostAllImages />} />
              <Route
                path="/set-web-link"
                element={<SetWebsiteLinkForSearch />}
              />
              <Route path="/change-logo" element={<AdminLogoChange />} />
              {/* Conditionally render Super Admin Route */}
              {userRole === "super_admin" && (
                <Route path="/super-admin" element={<SuperAdminPanel />} />
              )}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
