import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Registration.css";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate(); // Using useNavigate for redirection

  // Handle input change and clear error message
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setErrorMessage(""); // Clear error message when user starts typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email domain
    const emailDomain = "@walsystems.com";
    if (!email.endsWith(emailDomain)) {
      setErrorMessage("User Not Valid");
      return;
    }

    // Validate passwords
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    // Validate Employee ID format (BCS or BESTO followed by 1 to 3 digits)
    const employeeIdPattern = /^(BCS|BESTO)[0-9]{1,3}$/;
    if (!employeeId.match(employeeIdPattern)) {
      setErrorMessage(
        "Employee ID must start with 'BCS' or 'BESTO' and be followed by 1 to 3 digits, and in uppercase."
      );
      return;
    }

    try {
      const response = await axios.post(
        "https://kborder.onrender.com/api/admin/register",
        {
          name,
          email,
          mobileNumber,
          employeeId,
          password,
          confirmPassword,
        }
      );

      // Save the token if registration is successful
      if (response.data && response.data.token) {
        localStorage.setItem("authToken", response.data.token);
        setSuccessMessage("Registration successful!");

        // Redirect to the login page
        setTimeout(() => {
          navigate("/"); // Assuming "/" is the login page
        }, 2000);
      } else {
        setErrorMessage("Token not received. Registration failed.");
      }
    } catch (error) {
      setErrorMessage(
        error.response ? error.response.data.message : "Registration failed"
      );
    }
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h2 className="register-title">Admin Registration</h2>
        {errorMessage && (
          <p className="error-message animate-fade-in">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="success-message animate-fade-in">{successMessage}</p>
        )}
        <form className="form-registration" onSubmit={handleSubmit}>
          <div className="form-group-registration">
            <label>Name</label>
            <input
              type="text"
              className="form-input-registration"
              placeholder="Enter your full name"
              value={name}
              onChange={handleInputChange(setName)}
              required
            />
          </div>
          <div className="form-group-registration">
            <label>Email</label>
            <input
              type="email"
              className="form-input-registration"
              placeholder="Enter your email"
              value={email}
              onChange={handleInputChange(setEmail)}
              required
            />
          </div>
          <div className="form-group-registration">
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-input-registration"
              placeholder="Enter your mobile number"
              value={mobileNumber}
              onChange={handleInputChange(setMobileNumber)}
              required
            />
          </div>
          <div className="form-group-registration">
            <label>Employee ID</label>
            <input
              type="text"
              className="form-input-registration"
              placeholder="Enter your employee ID"
              value={employeeId}
              onChange={handleInputChange(setEmployeeId)}
              required
            />
          </div>
          <div className="form-group-registration">
            <label>Password</label>
            <input
              type="password"
              className="form-input-registration"
              placeholder="Create a password"
              value={password}
              onChange={handleInputChange(setPassword)}
              required
            />
          </div>
          <div className="form-group-registration">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-input-registration"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={handleInputChange(setConfirmPassword)}
              required
            />
          </div>
          <button type="submit" className="register-button animate-button">
            Register
          </button>
        </form>
        <div className="login-link">
          <p>
            I have an account{" "}
            <a href="/" className="register-page-link">
              Login here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
