import React, { useEffect, useState } from "react";
import axios from "axios";
import "./GetQuote.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function GetQuote() {
  const [quote, setQuote] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track the expanded quote index

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await axios.get(
          "https://kborder.onrender.com/api/quotes/get-request-quote"
        );
        console.log(response.data); // Data returned from the server
        setQuote(response.data); // Set the fetched data to state
      } catch (error) {
        console.error("Error fetching quotes:", error);
      }
    };

    fetchQuote(); // Call the function to fetch the data
  }, []);

  const toggleQuote = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div>
      <h2>Quote Requests</h2>
      {quote.length > 0 ? (
        quote.map((item, index) => (
          <div
            key={index}
            className={`quote-card ${
              expandedIndex === index ? "expanded" : ""
            }`}
          >
            <button
              className="quote-toggle-button"
              onClick={() => toggleQuote(index)}
            >
              {item.firstName} {item.lastName}
              <span className="arrow-icon">
                {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </button>
            {expandedIndex === index && (
              <div className="quote-details">
                <div className="quote-header">
                  <p>
                    <strong>Company:</strong> {item.companyName}
                  </p>
                </div>
                <div className="quote-body">
                  <p>
                    <strong>Email:</strong> {item.email}
                  </p>
                  <p>
                    <strong>Annual Revenue:</strong> ₹{item.annualRevenue}
                  </p>
                  <p>
                    <strong>Contact Number:</strong> {item.contactNumber}
                  </p>
                  <p>
                    <strong>Country:</strong> {item.country}
                  </p>
                  <p>
                    <strong>Business Type:</strong> {item.businessType}
                  </p>
                  <p>
                    <strong>Products:</strong>{" "}
                    {Array.isArray(item.products)
                      ? item.products.join(", ")
                      : item.products}
                  </p>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No quote requests available.</p>
      )}
    </div>
  );
}

export default GetQuote;
