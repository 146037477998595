import React from "react";
import "./VideoCompo.css";
import Video1 from "../src/assets/kbo_store_pick_up.mp4";
import Video2 from "../src/assets/KBO_Inventory_Management.mp4";

function VideoCompo() {
  return (
    <div className="home-page">
      <h1>Welcome to KBO Inventory Management</h1>

      <div className="video-container">
        {/* Video 1 */}
        <div className="video-wrapper">
          <h2>KBO Inventory Management</h2>
          <video controls className="video-player" muted autoPlay loop>
            <source src={Video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Video 2 */}
        <div className="video-wrapper">
          <h2>KBO Store Pickup</h2>
          <video controls className="video-player" muted autoPlay loop>
            <source src={Video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default VideoCompo;
